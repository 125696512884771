<template>
  <div class="pay">
    <!-- <div class="title"></div> -->
    <p class="paymoney margin-top-40">
      支付金额：{{ obj.amount ? obj.amount : obj.servicePrice }} 元
    </p>
    <div v-if="!obj.amount">
      <div class="col align-center pay-info">
        <template v-for="item in info">
          <div
            class="info row justify-between"
            :key="item.field"
            v-if="
              !(item.fieldText === '首付款' && obj && obj['payType'] === '全额')
            "
          >
            <div class="field">{{ item.fieldText }}</div>
            <div class="fieldVal">
              {{ obj[item.field] ? obj[item.field].toFixed(2) : "" }} 元
            </div>
          </div>
        </template>
        <div class="info row justify-between">
          <div class="field">分期金额</div>
          <div class="fieldVal">
            {{ (obj.servicePrice - obj.firstPayment).toFixed(2) }} 元
          </div>
        </div>
      </div>
      <!-- <div class="row fq">
        <div class="fq-item" v-for="i of numberOfStages" :key="i" @click="changePerid(i)" :class="{'active': curent === i}">
          <span>
            ￥{{ (Math.ceil(otherAmount/i*100)/100).toFixed(2) }}
          </span>
          <span>
            <span class="unit">{{i}} 期</span>
          </span>
        </div>
      </div> -->
      <div class="plan">
        温馨提示：<br />
        分期付款，系统设定还款日为每月15日。
        <div class="col margin-top-10" style="border: 1px solid #eee">
          <div
            class="row align-center plan-item"
            style="background-color: #eee"
          >
            <div class="num">期数</div>
            <div class="date">还款日期</div>
            <div class="amount">还款金额</div>
          </div>
          <div
            class="row align-center plan-item"
            v-for="item of curent"
            :key="item"
          >
            <div class="num">{{ item }}期</div>
            <div class="date">{{ payDate(item) }}</div>
            <div class="amount">
              ￥{{ (Math.ceil((otherAmount / curent) * 100) / 100).toFixed(2) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="margin: 16px" class="col justify-between btns">
      <van-button
        round
        block
        type="info"
        class=""
        style="letter-spacing: 2px"
        @click="pay"
        v-show="!isPay"
        >{{ obj.amount ? "支付" : "支付并前往签约" }}</van-button
      >
      <van-button
        round
        block
        type="info"
        class=""
        style="letter-spacing: 2px"
        @click="agreement"
        v-show="isPay && !isAgreement"
        >已付款前往实名认证</van-button
      >
      <!-- <van-button round block type="info" class="" style="letter-spacing: 2px;" @click="sign" v-show="isPay && isAgreement && !isSign">已付款前往签署合同</van-button> -->
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { getMultPolicy, payPolicy } from "@/api/extendedWarranty";
import { queryContractInfo, deployContract } from "@/api/sign";
import storage from "@/utils/storage";
const info = [{ fieldText: "首付款", field: "firstPayment" }];

export default {
  data() {
    return {
      info,
      curent: 1, // 当前选择期数
      numberOfStages: 3, // 分期数
      insuranceNo: "",
      payWay: "wxQR",
      isPay: false, // 已经付款
      isSign: false, // 已经签约
      isAgreement: false, // 已经签署代扣协议
      obj: {
        servicePrice: 0,
        firstPayment: 0,
      },
    };
  },
  async created() {
    if (/MicroMessenger/.test(window.navigator.userAgent)) {
      // 微信客户端
      this.payWay = "wxQR";
    } else if (/AlipayClient/.test(window.navigator.userAgent)) {
      // 支付宝客户端
      this.payWay = "alipayQR";
    } else {
      // 其他浏览器
      this.payWay = "quickpassQR";
    }

    this.insuranceNo = this.$route.query.insuranceNo;
    storage.setItem("insuranceNo", this.insuranceNo);
    if (this.insuranceNo) {
      const res = await getMultPolicy({
        insuranceNo: this.insuranceNo,
        payWay: this.payWay,
      });
      if (!res.success) {
        this.$toast.fail(res.respMag);
        return;
      }
      // 保存订单及银行卡和个人信息
      storage.setItem("info", res.content);
      this.obj = res.content;
      this.curent = res.content.periods ? res.content.periods : 3;
      const info = await queryContractInfo({ insuranceNo: this.insuranceNo });
      if (info.success) {
        this.isPay = info.content.finishPayment;
        this.isSign = info.content.signContract;
        this.isAgreement = info.content.signAgreement;
        // 2022.03.23 去掉契约锁签约功能， 已完成首付并去通联实名的， 直接跳转到完成界面
        if (this.isPay && this.isAgreement) {
          this.$toast.fail("订单已经完成");
          setTimeout(() => {
            this.$router.push({ path: "/sign/account" });
          }, 2000);
        }
      }
    }
  },
  computed: {
    otherAmount() {
      return this.obj.servicePrice - this.obj.firstPayment;
    },
  },
  methods: {
    changePerid(i) {
      if (this.isPay) return;
      this.curent = i;
    },
    async sign() {
      const res = await deployContract({ insuranceNo: this.insuranceNo });
      if (res.success) {
        window.location.href = res.content.signUrl;
      } else {
        this.$toast(res.respMag);
      }
    },
    agreement() {
      this.$router.push({
        path: "/wechat_pay/bind_card",
        query: { insuranceNo: this.insuranceNo },
      });
    },
    async pay() {
      if (this.obj.amount) {
        window.location.href = this.obj.codeUrl;
        return;
      }
      const param = {
        insuranceNo: this.insuranceNo,
        periods: this.curent,
        userId: this.obj.userId,
        payWay: this.payWay,
      };
      const resp = await payPolicy(param);
      if (!resp.success) {
        this.$toast.fail(
          "支付出现异常 " + resp.content && resp.content.returnMsg
        );
        return;
      }
      if (resp.success && resp.content.codeUrl) {
        // 支付流程
        window.location.href = resp.content.codeUrl;
      }
    },
    payDate(n) {
      const cdate = moment().format("YYYY-MM");
      const nDate = moment(`${cdate}-15`).add(n, "months").format("YYYY-MM-DD");
      return nDate;
    },
  },
};
</script>
<style scoped>
.pay {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  padding: 20px 30px;
  min-height: 100vh;
}

.paymoney {
  font-size: 22px;
  /* margin-top: 20px; */
}

.title {
  width: 100%;
  text-align: center;
  font-size: 18px;
  line-height: 40px;
  font-weight: 600;
  margin-bottom: 80px;
}

.pay-info {
  width: 100%;
  /* padding: 0 40px; */
  margin-top: 30px;
}

.info {
  font-size: 14px;
  width: 100%;
  margin-top: 10px;
  padding: 6px;
  border-bottom: 1px solid #ddd;
}

.info .field {
  color: #333;
}

.info .fieldVal {
  color: #666;
}

.btns {
  width: 100%;
}

.fq {
  width: 100%;
  margin: 20px 0px;
}

.fq-item {
  width: calc((100% - 15px) / 3);
  height: 80px;
  font-size: 0.42667rem;
  background-color: #eee;
  color: #333;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.fq-item:not(:last-child) {
  margin-right: 4px;
}

.active {
  background-color: #b6d3f4;
}

.unit {
  font-size: 10px;
  color: #666;
}

.plan {
  width: 100%;
  padding-top: 40px;
  font-size: 14px;
  color: #666;
}
.plan .plan-item {
  border-bottom: 1px solid #eee;
  padding: 10px 0;
}
.plan .plan-item div {
  padding-left: 20px;
}

.sign {
  letter-spacing: 2px;
  font-size: 18px;
  line-height: 40px;
  height: 40px;
  text-align: center;
  color: #1989fa;
}
</style>
